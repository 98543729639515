<template>
  <ClientOnly>
    <div class="flex flex-wrap space-x-2.5" :class="{'pr-4 border-r border-lightgray' : selectedVariant?.wholesalePrice > 0}">
        <!-- Kampanya Aktif ise -->
        <div class="product-price-wrapper with-campaign" v-if="discountPriceExists">
          <DiscountPercent :selected-variant="selectedVariant" direction="horizontal" class="!relative inline-flex !m-0" v-if="(showListPrice && showListPriceAndCampaignTogether === 1)" />

          <div class="product-price-content">
            <div class="flex gap-2.5" v-if="showListPrice && showListPriceAndCampaignTogether === 1">
              <div class="product-list-price" v-if="showListPrice && selectedVariant?.primaryStockData?.wholesaleListPrice">
                {{ formatPrice(selectedVariant?.primaryStockData?.wholesaleListPrice, selectedVariant?.primaryStockData?.currency) }}
              </div>

              <div id="price" :data-old-price="selectedVariant?.primaryStockData?.wholesalePrice">
                {{ formatPrice(selectedVariant?.primaryStockData?.wholesalePrice, selectedVariant?.primaryStockData?.currency) }}
              </div>
            </div>

            <div class="flex items-center gap-2" v-else>
              <DiscountPercent :selected-variant="selectedVariant" direction="horizontal" class="!relative inline-flex !m-0" />
              <div class="product-list-price" id="price" :data-old-price="selectedVariant?.primaryStockData?.wholesalePrice">
                {{ formatPrice(selectedVariant?.primaryStockData?.wholesalePrice, selectedVariant?.primaryStockData?.currency) }}
              </div>
            </div>

            <div class="product-sale-price" :class="discountRateIsExits(selectedVariant, isWholesaleUser) ? 'text-campaign-color' : 'text-price-color'" id="discountedPrice" :data-price="selectedVariant?.primaryStockData?.discountPrice">
              <span v-if="discountRateIsExits(selectedVariant, isWholesaleUser) && withInCartTextForCampaigns">{{ $t('listing.campaign-in-cart-text', [formatPrice(selectedVariant?.primaryStockData?.discountPrice, selectedVariant?.primaryStockData?.currency)]) }} </span>
              <span v-else>{{formatPrice(selectedVariant?.primaryStockData?.discountPrice, selectedVariant?.primaryStockData?.currency)}}</span>
            </div>

            <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
            <SectionProductVatRateInformation class="flex-1" id="vatRate" :selected-variant="selectedVariant" />
          </div>
        </div>

        <!-- Kampanya yok ise -->
        <div class="product-price-wrapper" v-if="!discountPriceExists">
            <DiscountPercent :selected-variant="selectedVariant" direction="horizontal" class="!relative inline-flex !m-0" />

            <div class="product-price-content">
              <div class="product-list-price" v-if="showListPrice && selectedVariant?.primaryStockData?.wholesaleListPrice > selectedVariant?.primaryStockData?.wholesalePrice" id="wholesaleListPrice" :data-old-price="selectedVariant?.primaryStockData?.wholesaleListPrice">
                {{ formatPrice(selectedVariant?.primaryStockData?.wholesaleListPrice, selectedVariant?.primaryStockData?.currency) }}
              </div>

              <div class="flex items-center gap-2.5">
                  <div class="product-sale-price text-price-color" id="wholesalePrice" :data-price="selectedVariant?.primaryStockData?.wholesalePrice">
                  {{ formatPrice(selectedVariant?.primaryStockData?.wholesalePrice, selectedVariant?.primaryStockData?.currency) }}
                  </div>

                  <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
                  <SectionProductVatRateInformation class="flex-1" id="vatRate" :selected-variant="selectedVariant" />
              </div>
            </div>

        </div>
    </div>
  </ClientOnly>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { wholesaleConfig } = storeToRefs(storeConfig)

const {isVatExcludeForB2B} = useHelper()
const onlyShowQty = ref(false)

const {
  selectedVariant,
  product,
  discountPriceExists
} = await useProduct()

const {
  showListPrice,
  showListPriceAndCampaignTogether
} = useListing()

const strikethroughPrice = computed(() => unref(selectedVariant)?.primaryStockData?.discountPrice ? unref(selectedVariant)?.primaryStockData?.discountPrice : unref(selectedVariant)?.primaryStockData?.listPrice)

</script>